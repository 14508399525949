import React from 'react';
import * as styles from './Button.module.css';
import clsx from 'clsx';
import exitInterstitial from '../../hooks/openExit';

export default function Button({ className, type='button', target='_self', children, darkmode=false, onClick, goExternal= false, url='', title }) {

  const openExitPopup = exitInterstitial((state) => state.openInterstitial);

  const handleClick = (e) => {
    e.preventDefault();
    openExitPopup(url);
  };

  return (
    type === 'link' ?
      <a className={ clsx(styles.button, className, darkmode && styles.darkmode)} href={ url } target={target} title={ title } >
        <span className={ styles.text }>{ children }</span>
      </a>
    :
      <button className={ clsx(styles.button, className, darkmode && styles.darkmode)} onClick={ goExternal?handleClick:onClick} title={ title }>
        <div className={ styles.text }>{ children }</div>
      </button>
  );
}